import React, { useState, useEffect } from "react";
import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import PrimaryButton from "../../../components/button/PrimaryButton";
import FormModal from "../../../components/modal/FormModal";
import AddSlidingBanner from "../../../components/slider/AddSlidingBanner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteRequest, getRequest } from "../../../services/api";
import SliderTable from "../../../components/slider/SliderTable";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";

function isAdd(location) {
  if (
    location.pathname.indexOf("/slider") !== -1 &&
    location.pathname.indexOf("/create") !== -1
  ) {
    return true;
  }
  return false;
}

function isEdit(location) {
  if (
    location.pathname.indexOf("/slider") !== -1 &&
    location.pathname.indexOf("/edit") !== -1
  ) {
    return true;
  }
  return false;
}

function isDelete(location) {
  if (
    location.pathname.indexOf("/slider") !== -1 &&
    location.pathname.indexOf("/delete") !== -1
  ) {
    return true;
  }
  return false;
}

function Slider() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [sliderResponse, setSliderResponse] = useState(null);
  const [sliderToEdit, setSliderToEdit] = useState(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { id, page: pageNum } = params;

  useEffect(() => {
    getSliders();
  }, [params]);

  useEffect(() => {
    if (isAdd(location)) {
      setShowAddForm(true);
    } else if (isEdit(location)) {
      handleShowEdit();
    } else if (isDelete(location)) {
      setSliderToDelete(id);
      setShowDeleteConfirmation(true);
    }
  }, [location]);

  const dismissAddForm = () => {
    setShowAddForm(false);
    setSliderToEdit(null);
    setSliderToDelete(null);
    navigate("/slider", { replace: true });
  };

  const getSliders = async (page = pageNum) => {
    if (isNaN(page)) {
      page = 0;
    }
    page = page - 1;
    if (page < 0) {
      page = 0;
    }

    try {
      var path = `admin/slider`;

      const response = await getRequest(`${path}?page=${page}`, true);
      setSliders(response);
      setSliderResponse(response);
    } catch (err) {
      //console.log(err);
    }
  };

  const handleShowEdit = async () => {
    const slider = await getRequest(`admin/slider/${id}`, true);
    setSliderToEdit(slider);
    setShowAddForm(true);
  };

  const handleSliderDelete = async () => {
    try {
      await deleteRequest("admin/slider", sliderToDelete);
    } catch (err) {
      console.log(err);
    } finally {
      setShowDeleteConfirmation(false);
      navigate(-1);
    }
  };

  const handleConfirmationDismiss = () => {
    setShowDeleteConfirmation(false);
    navigate(-1);
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Sliding Banners"} />

            <Link to={"/slider/create"}>
              <span className="bg-green-700 text-white  active:bg-green-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:bg-green-800 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
                Add New
              </span>
            </Link>
          </SectionHeader>
        </div>

        <SliderTable sliders={sliders} offset={0} />
      </div>

      <FormModal
        hidden={!showAddForm}
        title={`${sliderToEdit === null ? "Add" : "Update"} Slider`}
        onDismiss={dismissAddForm}
      >
        <AddSlidingBanner onDismiss={dismissAddForm} slider={sliderToEdit} />
      </FormModal>

      {sliderToDelete !== null ? (
        <ConfirmDialog
          title="Delete Slider?"
          message={`Are your sure you want to delete the slider?`}
          visible={showDeleteConfirmation}
          onAccept={handleSliderDelete}
          onDismiss={handleConfirmationDismiss}
        />
      ) : null}
    </>
  );
}

export default Slider;
